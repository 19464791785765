<template>
<q-page class="flex flex-center">
  <div class="column text-center">
    <div>
      <img src="/statics/_demo/gift.fill.svg" width="88" class="on-bottom-xl"/>
    </div>
    <div class="text-faded on-bottom-lg">
      Wings is free during the beta period.
    </div>
    <div class="text-weight-regular">
      <q-btn dense class="inline-block text-secondary font-size-100p spaced-width">
        <span class="text-system-brand" @click="dialogAboutShowOpen">About Wings</span>
      </q-btn>
    </div>
  </div>
</q-page>
</template>

<script>
export default {
  name: 'PagePlans',
  props: ['dialogAboutShowOpen']
}
</script>
