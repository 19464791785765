var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-page", { staticClass: "flex flex-center" }, [
    _c("div", { staticClass: "column text-center" }, [
      _c("div", [
        _c("img", {
          staticClass: "on-bottom-xl",
          attrs: { src: "/statics/_demo/gift.fill.svg", width: "88" }
        })
      ]),
      _c("div", { staticClass: "text-faded on-bottom-lg" }, [
        _vm._v("\n      Wings is free during the beta period.\n    ")
      ]),
      _c(
        "div",
        { staticClass: "text-weight-regular" },
        [
          _c(
            "q-btn",
            {
              staticClass:
                "inline-block text-secondary font-size-100p spaced-width",
              attrs: { dense: "" }
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-system-brand",
                  on: { click: _vm.dialogAboutShowOpen }
                },
                [_vm._v("About Wings")]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }